import { loadStripe } from '@stripe/stripe-js'

export default {
  data () {
    return {
      stripe: null,
      unavailable: false,
      card: null,
      fee: 0,
      paymentRequest: null
    }
  },
  async created () {
    this.stripe = await loadStripe('pk_live_OMVdiT4EPYeP85TYcvvoBe7X00lo2FH2Jf')
  },
  methods: {
    async processAlipay () {
      this.loading = true

      const { paymentIntent } = (await this.$axios.get('/api/createAlipayPayment', {
        params: {
          hash: this.hash,
          crc: Date.now()
        }
      })).data
      const confirmResult = await this.stripe.confirmAlipayPayment(
        paymentIntent.client_secret,
        {
          return_url: 'https://pay4good.com/?hash=' + this.hash
        }
      )

      if (confirmResult.error) {
        // Report to the browser that the payment failed.
        this.text = 'Failed Payment'
        this.status = false
        this.summary = true
        this.loading = false
      } else {
        this.text = 'Successful Payment'
        this.status = true
        this.summary = true
        this.loading = false
        this.startCallback()
      }
    },
    async processCreditCard () {
      this.loading = true
      this.normal = false

      const { paymentIntent } = (await this.$axios.get('/api/createPayment', {
        params: {
          hash: this.hash,
          crc: Date.now()
        }
      })).data
      const confirmResult = await this.stripe.confirmCardPayment(
        paymentIntent.client_secret,
        {
          payment_method: {
            card: this.card
          }
        }
      )

      if (confirmResult.error) {
        // Report to the browser that the payment failed.
        this.text = 'Failed Payment'
        this.status = false
        this.summary = true
        this.loading = false
      } else {
        this.text = 'Successful Payment'
        this.status = true
        this.summary = true
        this.loading = false
        this.startCallback()
      }
    },
    async updatePaymentRequest (amount, country = 'US', currency = 'usd') {
      const factor = currency === 'clp' ? 1 : 100
      if (this.currentTips > 0) {
        this.paymentRequest.update({
          total: {
            label: 'Total',
            amount: parseInt((amount * factor + this.fee * factor + this.currentTips * factor))
          },
          displayItems: [
            {
              label: 'Token4Good',
              amount: parseInt(amount * factor)
            },
            {
              label: 'Tips',
              amount: parseInt(this.currentTips * factor)
            },
            {
              label: 'Fee',
              amount: parseInt(this.fee * factor)
            }
          ]
        })
      } else {
        this.paymentRequest.update({
          total: {
            label: 'Total',
            amount: parseInt((amount * factor + this.fee * factor))
          },
          displayItems: [
            {
              label: 'Token4Good',
              amount: parseInt(amount * factor)
            },
            {
              label: 'Fee',
              amount: parseInt(this.fee * factor)
            }
          ]
        })
      }
    },
    async loadStripe (amount, country = 'US', currency = 'usd') {
      const elements = this.stripe.elements()
      const fee = ((Number(amount) + 0.3) / (1 - 0.029 - 0.006) - Number(amount)).toFixed(2)
      // const fee = 0
      this.fee = fee
      const factor = currency === 'clp' ? 1 : 100
      const paymentRequest = this.stripe.paymentRequest({
        country: country,
        currency: currency,
        requestPayerEmail: true,
        total: {
          label: 'Total',
          amount: parseInt((amount * factor + fee * factor))
        },
        displayItems: [
          {
            label: 'Token4Good',
            amount: parseInt(amount * factor)
          },
          {
            label: 'Fee',
            amount: parseInt(fee * factor)
          }
        ]
      })
      this.paymentRequest = paymentRequest
      const paymentRequestButton = elements.create('paymentRequestButton', {
        paymentRequest
      })

      // Check if the Payment Request is available (or Apple Pay on the Web).
      const paymentRequestSupport = await paymentRequest.canMakePayment()
      console.log(paymentRequestSupport)
      this.unavailable = !paymentRequestSupport
      if (paymentRequestSupport) {
        // Display the Pay button by mounting the Element in the DOM.
        paymentRequestButton.mount('#payment-request-button')
      } else {
        const card = elements.create('card', { hidePostalCode: true })
        card.mount('#card-element')
        this.card = card
      }

      paymentRequest.on('paymentmethod', async (event) => {
        this.loading = true
        const { paymentIntent } = (await this.$axios.get('/api/createPayment', {
          params: {
            hash: this.hash,
            crc: Date.now()
          }
        })).data
        const confirmResult = await this.stripe.confirmCardPayment(
          paymentIntent.client_secret,
          {
            payment_method: event.paymentMethod.id
          }
        )

        if (confirmResult.error) {
          // Report to the browser that the payment failed.
          event.complete('fail')
          this.text = 'Failed Payment'
          this.status = false
          this.summary = true
          this.loading = false
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          event.complete('success')
          // Let Stripe.js handle the rest of the payment flow, including 3D Secure if needed.
          if (confirmResult.paymentIntent.status === 'requires_action') {
            const response = await this.stripe.confirmCardPayment(
              paymentIntent.client_secret
            )
            if (response.error) {
              console.log(response.error)
              this.text = 'Failed Payment'
              this.status = false
              this.summary = true
              this.loading = false
            } else {
              console.log('ok')
              console.log(response)
              this.text = 'Successful Payment'
              this.status = true
              this.summary = true
              this.loading = false
              this.startCallback()
            }
          } else {
            console.log('ok')
            console.log(confirmResult)
            this.text = 'Successful Payment'
            this.status = true
            this.summary = true
            this.loading = false
            this.startCallback()
          }
        }
      })
    }
  }
}
