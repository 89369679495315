export default {
  data () {
    return {
      prices: {}
    }
  },
  async mounted () {
    await this.updatePrices()
    this.ref = setInterval(() => this.updatePrices(), 30000)
  },
  beforeDestroy () {
    clearInterval(this.ref)
  },
  methods: {
    async updatePrices () {
      const result = await this.$axios.get('https://pay4good.com/api/getCurrencyPrices', {
        params: {
          crc: Date.now()
        }
      })
      result.data.usd = { usd: 1 }
      this.prices = result.data
    }
  }
}
