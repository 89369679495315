import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main/Main'
import Donations from '@/views/Donations/Donations'
import None from '@/views/None/None'
import Done from '@/views/Done/Done'
import Validate from '@/views/Validate/Validate'
import FAQ from '@/views/FAQ/FAQ'
import Welcome from '@/views/Welcome/Welcome'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    props: true
  },
  {
    path: '/donations',
    name: 'Donations',
    component: Donations
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/none',
    name: 'None',
    component: None
  },
  {
    path: '/done',
    name: 'Done',
    component: Done
  },
  {
    path: '/validate',
    name: 'Validate',
    component: Validate
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
