<template>
  <div class="Validate">
    <v-container fluid>
      <v-row>
        <v-col align-self="center" align="center">
          <v-img :src="require('@/assets/pay4good.svg')" contain width="640px" class="payLogo"/>
        </v-col>
      </v-row>
    </v-container>
    <div class="Box">
      <v-card color="white" class="elevation-0">
        <v-card-title>
          <v-container fluid class="pa-0">
            <v-row dense>
              <v-col class="font-weight-bold" align-self="center" align="center">Voucher Check</v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-container fluid v-if="loading">
            <v-row>
              <v-col align-self="center" align="center">
                <v-progress-circular class="py-6" indeterminate size="150" width="10" color="#08385d">Checking</v-progress-circular>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid class="pa-0" v-if="!loading">
            <v-row dense>
              <v-col align="center">
                <div class="title green--text" v-if="validate">Voucher is valid</div>
                <div class="title red--text" v-if="!validate">Voucher is invalid</div>
                <div class="subtitle-2 font-italic mt-12 mb-5" v-if="validate"><v-btn :href="'https://pay4good.com/api/downloadVoucher?hash=' + hash" plain text x-small>Download Voucher</v-btn>  </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <div class="logo hidden-md-and-down">
      <v-img :src="require('@/assets/logobew.svg')" max-width="200px"/>
    </div>
  </div>
</template>

<script>
import './_Validate.scss'
export default {
  name: 'Validate',
  data () {
    return {
      hash: null,
      validate: false,
      loading: true
    }
  },
  mounted () {
    console.log(this.$route.query.hash)
    this.$axios.get('/api/getVoucher', {
      params: {
        hash: this.$route.query.hash
      }
    }).then((result) => {
      this.hash = result.data.voucher.hash
      this.validate = true
      this.loading = false
    }).catch(() => {
      this.loading = false
    })
  }
}
</script>
