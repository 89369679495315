<template>
  <div class="Donations">
    <v-container fluid>
      <v-row>
        <v-col align-self="center" align="center">
          <v-img :src="require('@/assets/pay4good.svg')" contain max-width="640" class="payLogo"/>
        </v-col>
      </v-row>
    </v-container>
    <div class="Box" >
      <v-card color="white" class="elevation-0">
        <v-card-title>
          <v-container fluid class="pa-0">
            <v-row dense>
              <v-col class="font-weight-bold" align-self="center" align="center" color="#10395D">Donations</v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
            <v-container fluid class="pa-0">
              <v-row dense>
                <v-col align="center">
                  <v-btn @click="donation(5)" class="my-1" block color="#10395D" dark>
                    $ 5 USD
                  </v-btn>
                  <v-btn @click="donation(10)" class="my-1" block color="#10395D" dark>
                    $ 10 USD
                  </v-btn>
                  <v-btn @click="donation(50)" class="my-1" block color="#10395D" dark>
                    $ 50 USD
                  </v-btn>
                  <v-btn @click="donation(100)" class="my-1" block color="#10395D" dark>
                    $ 100 USD
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
        </v-card-text>
      </v-card>
    </div>
    <div class="logo hidden-md-and-down">
      <v-img :src="require('@/assets/logobew.svg')" max-width="200px"/>
    </div>
  </div>
</template>

<script>
import './_Donations.scss'
import { createPayment } from 'id4good.auth'

export default {
  name: 'Donations',
  data () {
    return {
    }
  },
  methods: {
    async donation (amount) {
      const payment = await createPayment('-MXDb2Qm0el4c8i_qWko', amount)
      window.location.href = 'https://pay4good.com/?hash=' + payment.hash
    }
  }
}
</script>
