<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
    <div id="version">
      {{ version }}
    </div>
    <!--<df-messenger
      chat-icon="https://pay4good.com/bot.svg"
      intent="WELCOME"
      chat-title="Support"
      agent-id="0f710bb5-b0a4-473d-9f17-2d034a3804ed"
      language-code="en"
    ></df-messenger>-->
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    version: ''
  }),
  mounted () {
    this.version = 'v' + process.env.VUE_APP_VERSION
  },
  methods: {}
}
</script>

<style>

body {
  background: #1D4770;
}

#app {
  background: #1D4770;
  /*background-image: url("../src/assets/background.svg");*/
  /* background-attachment: fixed;
   background-size: cover; */
}

#version {
  font-size: 0.8rem;
  position: fixed;
  color: #ffffff;
  bottom: 1px;
  right: 5px;
  z-index: 5;
}

df-messenger {
  --df-messenger-bot-message: #878fac;
  --df-messenger-button-titlebar-color: #3D81c3;
  --df-messenger-chat-background-color: #fafafa;
  --df-messenger-font-color: white;
  --df-messenger-send-icon: #878fac;
  --df-messenger-user-message: #3D81c3;
}

</style>
