<template>
  <div class="Done">
    <v-container fluid>
      <v-row>
        <v-col align-self="center" align="center">
          <v-img :src="require('@/assets/pay4good.svg')" contain width="640px" class="payLogo"/>
        </v-col>
      </v-row>
    </v-container>
    <div class="Box">
      <v-card color="white" class="elevation-0">
        <v-card-title>
          <v-container fluid class="pa-0">
            <v-row dense>
              <v-col class="font-weight-bold" align-self="center" align="center">Thanks</v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-container fluid class="pa-0">
            <v-row dense>
              <v-col align="center">
                <div class="title">Thank you for your donation</div>
                <div class="subtitle-2 font-italic mt-12 mb-5">Thank you for using Pay4Good</div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <div class="logo hidden-md-and-down">
      <v-img :src="require('@/assets/logobew.svg')" max-width="200px"/>
    </div>
  </div>
</template>

<script>
import './_Done.scss'
export default {
  name: 'Done'
}
</script>
