<template>
  <div class="FAQ">
    <v-container fluid>
      <v-row>
        <v-col align-self="center" align="center">
          <v-img :src="require('@/assets/pay4good.svg')" width="80%" max-width="500px" class="payLogo"/>
        </v-col>
      </v-row>
    </v-container>
    <div class="Box">
      <v-card color="white" class="elevation-0">
        <v-card-title>
          <v-container fluid class="pa-0">
            <v-row dense>
              <v-col class="font-weight-bold" align-self="center" align="center">FAQ</v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-expansion-panels accordion>
            <v-expansion-panel v-for="(item, index) in content" v-bind:key="index">
              <v-expansion-panel-header class="font-weight-bold">{{ item.question }}</v-expansion-panel-header>
              <v-expansion-panel-content style="white-space: pre-line">
                {{ item.answer }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </div>
    <div class="logo hidden-md-and-down">
      <v-img :src="require('@/assets/logobew.svg')" max-width="200px"/>
    </div>
  </div>
</template>

<script>
import './_FAQ.scss'

export default {
  name: 'FAQ',
  data () {
    return {
      content: []
    }
  },
  mounted () {
    this.$axios.get('https://pay4good.com/website/faq').then(result => {
      this.content = result.data
    })
  }
}
</script>
