<template>
  <div class="Summary">
    <v-card color="white" class="elevation-0" v-show="!qr && !summary && !loading && !normal && !chooseWallet">
      <v-card-title>
        <v-container fluid class="pa-0">
          <v-row dense>
            <v-col class="font-weight-bold" align-self="center" align="center" color="#10395D">Payment Request</v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="pa-0">
          <v-row>
            <v-col align="center" class="title">
              <strong>{{ store }}</strong>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              Amount: <strong> ${{ amount }} {{ currency.toUpperCase() }}</strong>
            </v-col>
          </v-row>
          <v-row v-if="!loading">
            <v-col align="center" class="subtitle-2">
              <div class="font-weight-bold">Others:</div>
              <div>$ {{ Object.keys(prices).length > 6 ? (amount * prices[currency].usd / prices.ethereum.usd).toPrecision(2) : '-' }} ETH</div>
              <div>$ {{ Object.keys(prices).length > 6 ? (amount * prices[currency].usd / prices.bitcoin.usd).toPrecision(2) : '-' }} BTC</div>
            </v-col>
          </v-row>
          <v-row v-if="tips">
            <v-col align="center" class="font-italic font-weight-bold">
              <div>Tips:</div>
              <v-btn x-small class="mx-1" :dark="selectedTips === '0'" :color="selectedTips === '0' ? '#10395D' : ''" @click="selectedTips = '0'; updateTips(selectedTips);">0%</v-btn>
              <v-btn v-if="$vuetify.breakpoint.width >= 350 && !telpo" x-small class="mx-1" :dark="selectedTips === '5'" :color="selectedTips === '5' ? '#10395D' : ''" @click="selectedTips = '5'; updateTips(selectedTips);">5%</v-btn>
              <v-btn x-small class="mx-1" :dark="selectedTips === '10'" :color="selectedTips === '10' ? '#10395D' : ''" @click="selectedTips = '10'; updateTips(selectedTips);">10%</v-btn>
              <v-btn v-if="$vuetify.breakpoint.width >= 350 && !telpo" x-small class="mx-1" :dark="selectedTips === '15'" :color="selectedTips === '15' ? '#10395D' : ''" @click="selectedTips = '15'; updateTips(selectedTips);">15%</v-btn>
              <v-btn x-small class="mx-1" :dark="selectedTips === '20'" :color="selectedTips === '20' ? '#10395D' : ''" @click="selectedTips = '20'; updateTips(selectedTips);">20%</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center" v-if="!telpo">
              <v-checkbox v-model="vDownload" dense hide-details class="voucher" label="Download voucher at the end"></v-checkbox>
              <v-checkbox v-model="vEmail" dense hide-details class="voucher" label="Send voucher to email"></v-checkbox>
              <v-fade-transition>
                <v-text-field outlined v-if="vEmail" v-model="email" class="voucher" type="email" hide-details dense label="email"></v-text-field>
              </v-fade-transition>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center" class="font-italic font-weight-bold">
              Pay with:
            </v-col>
          </v-row>
          <v-row dense>
            <v-col align="center" align-self="center">
              <v-btn @click="wallet4good" class="my-1" block color="#10395D" dark>
                <v-img class="mx-1" :src="require('@/assets/w4g.svg')" max-width="35" height="18" width="35" contain/>
                {{ ($vuetify.breakpoint.width < 350) ? 'W4G' : 'Wallet4Good' }}
              </v-btn>
              <v-btn @click="walletconnect" class="my-1" block color="#10395D" dark>
                <v-img class="mx-1" :src="require('@/assets/wc.svg')" max-width="18" height="18" width="18" contain/>
                {{  $vuetify.breakpoint.width < 350 ? 'Ethereum' : 'Ethereum Wallet' }}
              </v-btn>
              <v-btn @click="bitcoin" class="my-1" block color="#10395D" dark>
                <v-img class="mx-1" :src="require('@/assets/btc.svg')" max-width="18" height="18" width="18" contain/>
                {{  $vuetify.breakpoint.width < 350 ? 'Bitcoin' : 'Bitcoin Wallet' }}
              </v-btn>
              <v-btn @click="processAlipay" class="my-1" block color="#10395D" dark v-if="!telpo">
                <v-img class="mx-1" :src="require('@/assets/alipay.png')" max-width="18" height="18" width="18"
                       contain/>
                Alipay
              </v-btn>
              <v-btn v-if="unavailable && !telpo" @click="creditcard" class="my-1" block color="#10395D" dark>
                <v-icon color="green lighten-3" class="mx-1" size="18">mdi-credit-card-multiple-outline</v-icon>
                Credit Card
              </v-btn>
              <div class="v-btn--is-elevated rounded" id="payment-request-button" v-if="!telpo"></div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-container fluid v-show="chooseWallet && !normal && !summary && !loading && !qr">
      <v-row>
        <v-col align-self="center" align="center" class="font-weight-bold title">
          Select your Wallet
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="(link, id) in links" v-bind:key="id" cols="6" align-self="center" align="center"
               @click="getIOSUri(link)">
          <v-img :src="link.logo" max-width="64" max-height="64" height="64" width="64" contain/>
          <div>{{ link.name }}</div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-show="normal && !summary && !loading && !qr && !chooseWallet">
      <v-row>
        <v-col align-self="center" align="center" class="font-weight-bold title">
          Summary
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-card color="transparent" class="elevation-0">
            <v-card-text class="pa-0">
              <v-container>
                <v-row dense>
                  <v-col align="right" class="pr-5">
                    Amount:
                  </v-col>
                  <v-col align="left" class="pl-5">
                    <strong> ${{ amount }} {{ currency.toUpperCase() }}</strong>
                  </v-col>
                </v-row>
                <v-row dense v-if="tips">
                  <v-col align="right" class="pr-5">
                    Tips:
                  </v-col>
                  <v-col align="left" class="pl-5">
                    <strong> ${{ Number(amount) * Number(selectedTips / 100)  }} {{ currency.toUpperCase() }}</strong>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col align="right" class="pr-5">
                    Fee:
                  </v-col>
                  <v-col align="left" class="pl-5">
                    <strong> ${{ fee }} {{ currency.toUpperCase() }}</strong>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col align="right" class="pr-5">
                    Total:
                  </v-col>
                  <v-col align="left" class="pl-5">
                    <strong> ${{ Number(amount) * Number(1 + selectedTips / 100) + Number(fee) }} {{ currency.toUpperCase() }}</strong>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center">
          <strong>Card Number:</strong>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center" :class="{ 'pa-0': $vuetify.breakpoint.width < 400 }">
          <div id="card-element" :class="{ 'mx-10': $vuetify.breakpoint.width > 400, 'mb-10': true }"><!--Stripe.js injects the Card Element--></div>
          <v-btn class="my-1" block color="#10395D" dark @click="processCreditCard">Pay</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="qr && !summary && !loading && !normal && !chooseWallet">
      <v-row>
        <v-col align-self="center" align="center">
          <div v-if="btcCharge">
            <v-btn style="border: 1px solid black !important;" tile small :class="{ 'white--text': !link.includes('bitcoin:')  }" @click="changeToBitcoin" :color="link.includes('bitcoin:') ? 'white' : 'gray'">Bitcoin</v-btn>
            <v-btn style="border: 1px solid black !important;" tile small :class="{ 'white--text': !link.includes('lightning:')  }" @click="changeToLightning" :color="link.includes('lightning:') ? 'white' : 'gray'">Lightning</v-btn>
          </div>
          <div v-if="btcCharge && $device.mobile && !this.telpo">
            <v-btn dark class="my-10" large :href="this.link">Open Wallet</v-btn>
          </div>
          <v-img v-if="!$device.mobile || this.telpo" :src="image" contain width="300" height="300" class="qrcode"/>
          <v-btn outlined block @click="qr = false; btcCharge = false">BACK</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="loading && !summary && !normal && !chooseWallet">
      <v-row>
        <v-col align-self="center" align="center">
          <v-progress-circular class="py-6" indeterminate size="150" width="10" color="#08385d">Sync</v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="!loading && summary && !normal && !chooseWallet">
      <v-row>
        <v-col align-self="center" align="center">
          <div class="title">{{ text }}</div>
          <div class="my-5">Amount: <strong> ${{ amount }} {{ currency.toUpperCase() }}</strong></div>
          <v-btn v-if="!status" outlined block @click="summary = false">Please Try Again</v-btn>
          <div v-if="telpo" class="py-5 subtitle-2" align="center">
            <div align="center">
              <v-btn dark small :href="print">Print Voucher</v-btn>
            </div>
            <div align="center">
              <v-btn outlined x-small href="https://box4good.com">Close</v-btn>
            </div>
          </div>
          <div v-if="callback && status" class="py-10 subtitle-2">
            Redirect in {{ count }}
            <div class="red--text my-3 overline font-weight-bold">
              Don't close this window
            </div>
            <div>
              <v-btn text x-small @¢lick="redirectCallback">Manual redirect</v-btn>
            </div>
          </div>
          <div class="subtitle-2 font-italic mt-12 mb-5">Thank you for using Pay4Good</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_Summary.scss'
import WalletConnect from '@walletconnect/client'
import {
  getWalletRegistryUrl,
  getMobileLinkRegistry,
  formatMobileRegistry,
  formatIOSMobile
} from '@walletconnect/browser-utils'
import QRCodeStyling from 'qr-code-styling'
import stripe from '@/mixins/stripe'
import { waitPayment, generateQR } from 'id4good.auth'

import prices from '@/mixins/prices'

export default {
  name: 'Summary',
  mixins: [stripe, prices],
  data () {
    return {
      print: null,
      telpo: false,
      vDownload: false,
      vEmail: false,
      chooseWallet: false,
      normal: false,
      summary: false,
      amount: 0,
      currentTips: 0,
      country: 'US',
      currency: 'usd',
      ref: null,
      email: null,
      link: null,
      image: null,
      qr: false,
      result: false,
      status: false,
      loading: false,
      text: '',
      tips: false,
      selectedTips: '15',
      payment: null,
      store: '',
      callback: null,
      count: 10,
      links: [],
      btcCharge: null,
      wait: false
    }
  },
  beforeDestroy () {
    clearInterval(this.ref)
  },
  async mounted () {
    this.telpo = navigator.userAgent.includes('TPS')
    if (this.$vuetify.breakpoint.width < 350) {
      this.selectedTips = '10'
    }
    this.loading = true
    await this.loadRequest()
    if (this.payment.percentTips) {
      this.selectedTips = this.payment.percentTips.toString()
    }
    if (!this.telpo) {
      await this.loadStripe(this.amount, this.country, this.currency)
    }
    this.updateTips(this.selectedTips)
    this.loading = false
  },
  methods: {
    processVoucher () {
      if (this.vDownload || this.vEmail || this.telpo) {
        this.count = 5
        this.$axios.get('/api/getVoucherByToken', {
          params: {
            token: this.hash
          }
        }).then(async result => {
          const voucher = result.data.voucher
          if (this.vEmail) {
            await this.$axios.get('/api/sendVoucherToEmail', {
              params: {
                hash: voucher.hash,
                email: this.email,
                crc: Date.now()
              }
            })
          }
          if (this.vDownload) {
            this.count = 10
            window.location.href = 'https://pay4good.com/api/downloadVoucher?hash=' + voucher.hash
            // window.open('https://pay4good.com/api/downloadVoucher?hash=' + voucher.hash, '_blank')
          }
          if (this.telpo) {
            this.print = 'pos://controller/' + voucher.hash + ';scheme=post;package=com.bloqs4good.controllerpos;end'
          }
          this.qr = false
          this.text = 'Successful Payment'
          this.status = true
          this.summary = true
          this.loading = false
          clearInterval(this.wait)
          this.startCallback()
        })
      } else {
        this.qr = false
        this.text = 'Successful Payment'
        this.status = true
        this.summary = true
        this.loading = false
        clearInterval(this.wait)
        this.startCallback()
      }
    },
    updateTips (percent) {
      this.selectedTips = percent
      this.currentTips = Number(this.amount) * (percent / 100)
      this.updatePaymentRequest(this.amount, this.country, this.currency)
      this.$axios.get('https://id4good.com/api/updatePaymentTips', {
        params: {
          hash: this.hash,
          tips: percent,
          crc: Date.now()
        }
      })
    },
    getIOSUri (entry) {
      console.log(this.link)
      window.location.href = formatIOSMobile(this.link, entry)
    },
    startCallback () {
      if (this.callback === null || this.callback === false || this.callback === undefined || this.callback === '') {
        return
      }
      this.count--
      if (this.count === 0) {
        return this.redirectCallback()
      }
      setTimeout(() => this.startCallback(), 1000)
    },
    redirectCallback () {
      window.location.href = this.callback + '?status=' + (this.status ? 'success' : 'fail')
    },
    async loadRequest () {
      this.hash = this.$route.query.hash
      console.log(this.hash)

      if (this.hash === undefined || this.hash === null || this.hash === '') {
        console.log('welcome')
        await this.$router.push({ name: 'Welcome' })
        return
      }

      const payment = await this.$axios.get('https://id4good.com/api/waitPayment', {
        params: {
          hash: this.hash,
          crc: Date.now()
        }
      })
      if (payment.data === '') {
        await this.$router.push({ name: 'None' })
      }
      this.payment = payment.data

      if (this.payment.status === 'reject' || this.payment.status === 'fail' || this.payment.status === 'expire') {
        await this.$router.push({ name: 'None' })
      }
      this.amount = this.payment.mount
      this.country = this.payment.country
      this.currency = this.payment.currency
      this.store = this.payment.site
      this.callback = this.payment.callback
      console.log(this.payment)
      this.tips = !!this.payment.tips
      if (!this.tips) {
        this.selectedTips = '0'
      }

      if (this.$route.query.redirect_status) {
        if (this.$route.query.redirect_status !== 'succeeded') {
          // Report to the browser that the payment failed.
          this.text = 'Failed Payment'
          this.status = false
          this.summary = true
          this.loading = false
        } else {
          this.processVoucher()
        }
      }
    },
    async wallet4good () {
      if (this.$device.mobile && !this.telpo) {
        if (this.$device.ios) {
          window.location.href = 'https://identity4good.web.app/' + this.payment.code
        }
        if (this.$device.android) {
          window.location.href = 'id4good://wallet4good/' + this.payment.code + ';scheme=id4good;package=com.bloqs4good.id4good;end'
        }
      } else {
        this.image = await generateQR(this.payment.code)
        this.qr = true
      }
      waitPayment(this.hash, 0, true).then(() => {
        this.processVoucher()
      }).catch(() => {
        this.qr = false
        this.text = 'Failed Payment'
        this.status = false
        this.summary = true
        this.loading = false
      })
    },
    async creditcard () {
      this.normal = true
    },
    async generateQR (logo = require('@/assets/wc.png')) {
      const qrcode = new QRCodeStyling({
        width: 250,
        height: 250,
        margin: 4,
        data: this.link,
        image: logo,
        dotsOptions: {
          color: '#10395D',
          type: 'rounded'
        },
        qrOptions: {
          errorCorrectionLevel: 'H'
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: '#10395D'
        },
        backgroundOptions: {
          color: '#ffffff'
        },
        imageOptions: {
          imageSize: 0.5,
          crossOrigin: 'anonymous',
          margin: 3
        }
      })
      console.log('qrcode', qrcode)
      await qrcode._canvasDrawingPromise
      this.image = await qrcode._canvas.getCanvas().toDataURL('image/png')
    },
    async changeToBitcoin () {
      this.loading = true
      const charge = this.btcCharge
      this.link = charge.uri
      await this.generateQR(require('@/assets/btc.svg'))
      this.qr = true
      this.loading = false
    },
    async changeToLightning () {
      this.loading = true
      const charge = this.btcCharge
      this.link = 'lightning:' + charge.lightning_invoice.payreq
      await this.generateQR(require('@/assets/LN.svg'))
      this.qr = true
      this.loading = false
    },
    async bitcoin () {
      this.loading = true
      const { charge } = (await this.$axios.get('/api/createBitcoinPayment', {
        params: {
          hash: this.hash,
          crc: Date.now()
        }
      })).data
      this.btcCharge = charge
      this.link = 'lightning:' + charge.lightning_invoice.payreq
      await this.generateQR(require('@/assets/LN.svg'))
      this.qr = true
      this.loading = false
      console.log(charge)
      this.wait = setInterval(async () => {
        if (this.qr === false) {
          clearInterval(this.wait)
          return
        }
        const { charge } = (await this.$axios.get('/api/waitBitcoinPayment', {
          params: {
            id: this.btcCharge.id,
            crc: Date.now()
          }
        })).data
        if (charge.status !== 'unpaid' && charge.status !== 'paid') {
          this.loading = true
        }
        if (charge.status === 'paid') {
          this.processVoucher()
        }
      }, 2000)
    },
    async walletconnect () {
      this.loading = true
      localStorage.clear()

      this.connector = new WalletConnect({
        bridge: 'https://bridge.walletconnect.org',
        clientMeta: {
          name: 'Pay4Good'
        }
      })
      await this.connector.createSession()
      // this.connector.rpcUrl = 'http://localhost:7545/'
      this.link = this.connector.uri

      if (this.$device.mobile && !this.telpo) {
        if (this.$device.ios) {
          const registry = await (await fetch(getWalletRegistryUrl())).json()
          this.links = getMobileLinkRegistry(formatMobileRegistry(registry), undefined)
          console.log(this.links)
          this.chooseWallet = true
        }
        if (this.$device.android) {
          window.location.href = this.link
        }
      } else {
        await this.generateQR()
        this.qr = true
      }
      this.loading = false

      if (this.connector.connected) {
        console.log('is connected')
        this.transaction()
      }

      this.connector.on('connect', () => {
        console.log('connect')
        this.transaction()
      })
      this.connector.on('session_update', () => {
        console.log('session_update')
        this.transaction()
      })
    },
    transaction () {
      this.qr = false
      this.chooseWallet = false
      this.loading = true
      console.log('tx')
      const amount = parseInt(this.amount * this.prices[this.currency].usd * 1.015 * 10 ** 18 / this.prices.ethereum.usd, 10).toString(16)

      console.log(this.payment.wallet)

      const tx = {
        from: this.connector.accounts[0], // Required
        to: '0x60D5C29a464A439c4887E5576e0FED9Bb7857A02', // Required (for non contract deployments)
        data: '0x', // Required
        value: '0x' + amount // Optional
      }
      this.connector
        .sendTransaction(tx)
        .then(async (result) => {
          console.log(result)
          const { payment } = (await this.$axios.get('/api/verifyPayment', {
            params: {
              hash: this.hash,
              txEthereum: result,
              crc: Date.now()
            }
          })).data
          if (payment === 'success') {
            this.processVoucher()
          } else {
            this.text = 'Failed Payment'
            this.status = false
            this.summary = true
            this.loading = false
          }
        })
        .catch((error) => {
          console.log(error)
          this.text = 'Failed Payment'
          this.status = false
          this.summary = true
          this.loading = false
        })
    }
  }
}
</script>
