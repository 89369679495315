<template>
  <div class="Main">
    <v-container fluid>
      <v-row>
        <v-col align-self="center" align="center">
          <v-img :src="require('@/assets/pay4good.svg')" contain max-width="640" class="payLogo" />
        </v-col>
      </v-row>
    </v-container>
    <Summary v-if="summary" />
    <div class="logo hidden-md-and-down">
      <v-img :src="require('@/assets/logobew.svg')" max-width="200px"/>
    </div>
    <div class="faq">
      <v-icon color="blue darken-4">mdi-help-circle-outline</v-icon>
    </div>
  </div>
</template>

<script>
import './_Main.scss'
import Summary from '@/components/Summary/Summary'

export default {
  name: 'Main',
  components: { Summary },
  data () {
    return {
      summary: false
    }
  },
  async mounted () {
    this.summary = true
  },
  methods: {
  }
}
</script>
