<template>
  <div class="Welcome">
    <v-container fluid>
      <v-row>
        <v-col align-self="center" align="center">
          <v-img :src="require('@/assets/p4g.svg')" contain max-width="640" class="payLogo"/>
        </v-col>
      </v-row>
    </v-container>
    <v-expand-transition>
      <v-container>
        <v-row  style="height: 25vh">
          <v-col align-self="center" align="center" class="display-1  text-uppercase white--text">
            Levelling the Field for SME's
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-img :src="require('@/assets/payment-method.svg')" contain max-height="120" />
            <div class="py-5 text-uppercase font-weight-bold subtitle-2 white--text">Digital and secure payment system</div>
          </v-col>
          <v-col align="center">
            <v-img :src="require('@/assets/networking.svg')" contain max-height="120" />
            <div class="py-5 text-uppercase font-weight-bold subtitle-2 white--text">Connecting people with new technologies</div>
          </v-col>
          <v-col align="center">
            <v-img :src="require('@/assets/shield.svg')" contain max-height="120" />
            <div class="py-5 text-uppercase font-weight-bold subtitle-2 white--text">Security First, Your Identity always safe</div>
          </v-col>
          <v-col align="center">
            <v-img :src="require('@/assets/credit-card.svg')" contain max-height="120" />
            <div class="py-5 text-uppercase font-weight-bold subtitle-2 white--text">Support of traditional payment methods</div>
          </v-col>
        </v-row>
        <v-row class="pt-10">
          <v-col align-self="center" align="center">
            <div class="text-uppercase font-weight-bold py-10 white--text">
              Pay from the comfort of your personal device.
            </div>
            <div style="max-width: 300px">
              <!-- <v-img :src="require('@/assets/iphone.png')" contain max-width="300" /> -->
            </div>
          </v-col>
        </v-row>
        <v-row class="pt-10">
          <v-col align-self="center" align="center">
          </v-col>
        </v-row>
      </v-container>
    </v-expand-transition>
    <v-container fluid>
      <v-row>
        <v-col align-self="center" align="center">
          <v-img :src="require('@/assets/logobew.svg')" contain max-width="300"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_Welcome.scss'

export default {
  name: 'Welcome'
}
</script>
